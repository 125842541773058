import React from 'react';
import Banner from './components/Banner'
import FirstFloor from './components/FirstFloor'
import SecondFloor from './components/SecondFloor'
import ThirdFloor from './components/ThirdFloor'
import BottomText from './components/BottomText'

function App() {
  return (
    <main>
      <Banner />
      <FirstFloor />
      <SecondFloor />
      <ThirdFloor />
      <BottomText />
    </main>
  );
}

export default App;
