import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";


const Nav = styled.nav`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  padding: 1em 0;
  font-size: 16px;
  font-weight: bold;
`
const NavLeft = styled.div`
  background-color: #fff;
  width: 15%;
  img {
    width: 100%;
  }
`
const NavRight = styled.div`
  li {
    float: left;
    padding: 0 2em;
    a {
      position: relative;
    }
    a::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -.8em;
      width: 100%;
      height: 2px;
      background-color: #fc2f70;
      transform-origin: center;
      transform: translate(-50%, 0) scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
    a:hover {
      color: #FE6746;
    }
    a:hover::before {
      transform: translate(-50%, 0) scaleX(1);
    }
  }
`

const navList = [{path: '/home', text: '首页'}, {path: '/market', text: '全网通营销平台'}, {path: 'http://www.globalcharge.cn/', text: '游全球'}, {path: '/connect', text: '全球短信/全球通话'}, {path: '/about', text: '关于我们'}]
const navListItems = navList.map((value, index) => {
  const aLink = value.path.indexOf('http') >= 0 ? <a href={value.path} target="_blank" rel="noopener noreferrer">{value.text}</a> : <NavLink to={value.path} activeClassName="active-header">{value.text}</NavLink>
  return <li key={index}>{aLink}</li>
});

function Header() {
  return (
    <header>
      <Nav className="g-width">
        <NavLeft>
          <img src={require('../assets/nav/logo.png')} alt="logo"/>
        </NavLeft>
        <NavRight>
          <ul className="clearfix">{navListItems}</ul>
        </NavRight>
      </Nav>
    </header>
  );
}

export default Header;
