import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { Pagination, Autoplay } from 'swiper/dist/js/swiper.esm';
import { Link } from "react-router-dom";
import 'swiper/dist/css/swiper.min.css'

const BannerText = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: left;
  h1 {
    font-size: 30px;
    margin-bottom: 1em;
  }
  p {
    font-size: 16px;
    line-height: 2.5;
  }
  a {
    margin-top: 3em;
    display: inline-block;
    background-image: linear-gradient(to right, rgb(0,118,248), rgb(82,222,255));
    padding: 1em 3em;
    border-radius: 1.5em;
  }
`
function BannerInfo({h1, p, a}) {
  return (
    <BannerText className="g-width">
      <h1>{h1}</h1>
      { p.map(v => <p key={v}>{v}</p>) }
      {a.path.indexOf('http') >= 0 ? <a href={a.path} target="_blank" rel="noopener noreferrer">{a.text}</a> : <Link to={a.path}>{a.text}</Link>}
    </BannerText>
  )
}

const info = [
  { img: require('../../../assets/banner/banner1.jpg'), h1: '全球话费流量充值服务——解决海外中国人充值难题', p: ['24小时在线充值', '全中文易操作', '微信、支付宝支付'], a: {path: 'http://wechat.globalcharge.cn/home', text: '免费体验'} },
  { img: require('../../../assets/banner/banner2.jpg'), h1: '全网通营销平台', p: ['全面、有效的运营体系—— 专注用户运营 全链路专业服务', '活动化营销手段—— 为广告主提供定制化解决方案', '媒体流量变现——无门槛、高回报，一键自助投放'], a: {path: '/market', text: '立即加入'} },
  { img: require('../../../assets/banner/banner3.jpg'), h1: '优质云通讯资源——全球语音、短信、流量、话费', p: ['接入简单、通道直达', '价格公道、节约成本', '稳定可靠、专业安全', '响应快捷，实时解决问题'], a: {path: '/connect', text: '了解详情'} }
]
const SwiperItems = info.map((v, index) => (
  <div key={index}>
    <img width="100%" src={v.img} alt="banner"/>
    <BannerInfo h1={v.h1} p={v.p} a={v.a} />
  </div>
))

function Swipers() {
  const params = {
    modules: [Pagination, Autoplay],
    autoplay : {
      delay: 3000
    },
    pagination: {
      el: '.swiper-pagination'
    }
  }
  return (
    <div className="g-min-width">
      <Swiper {...params}>{SwiperItems}</Swiper>
    </div>
  )
}

export default Swipers;