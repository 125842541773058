import React from 'react';
import styled from 'styled-components'

const Wrap = styled.footer`
  background-color: #000;
  padding: 3em 0;
  color: #878791;
  p {
    line-height: 2;
  }
`
const LeftBar = styled.div`
  .title {
    margin-bottom: 1em;
    span {
      font-size: 16px;
      color: #fff;
      padding-right: 1em;
    }
  }
  p:last-child {
    text-indent: 2em;
  }
`
const RightBar = styled.div`
  text-align: right;
  >div {
    display: inline-block;
    margin-left: 1em;
    color: #fff;
    text-align: center;
  }
`
const CutBar = styled.div`
  border-top: 1px dashed #3B3B48;
  padding-top: 3em;
  margin-top: 3em !important;
`

function App() {
  return (
    <Wrap className="g-min-width">
      <div className="g-width g-flex">
        <LeftBar>
          <p className="title"><span>金橘科技</span>移动互联网服务的高新技术企业</p>
          <p>立足技术开发，发挥资源渠道优势，结合营销策划能力，围绕“通讯服务”领域，为企业提供优质、便捷、高效的移动互联网综合服务。目前金橘科技已与多家知名企业达成长期稳定合作关系，为其提供移动商务应用服务，帮助其及时、准确地传递资讯和服务信息。领域涉及跨国银行、国际公司、跨境电商、旅游公司、出海游戏公司、出海制造业等。</p>
        </LeftBar>
        <RightBar>
          <div>
            <img src={require('../assets/footer/qrcode1.png')} alt=""/>
            <p>领流量微信公众号</p>
          </div>
          <div>
            <img src={require('../assets/footer/qrcode2.png')} alt=""/>
            <p>游全球微信公众号</p>
          </div>
        </RightBar>
      </div>
      <CutBar className="g-width g-flex">
        <LeftBar>
          <p>杭州金橘科技有限公司，任何单位及个人未经许可，不得擅自转载使用。</p>
          <p></p>
        </LeftBar>
        <RightBar>
          <p><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备14029005号</a></p>
          <p>增值电信业务经营许可证：浙B1.B2-20150562 
            </p>
        </RightBar>
      </CutBar>
    </Wrap>
  );
}

export default App;
