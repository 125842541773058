import React from 'react';

function App() {
  return (
    <div className="g-min-width">
      <img width="100%" src={require('../../../assets/connect/banner.png')} alt="全球合作"/>
    </div>
  );
}

export default App;
