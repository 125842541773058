import React from 'react';
import styled from 'styled-components'

const Wrap = styled.div`
  padding: 5em 0;
`
const Info = styled.div`
  padding-right: 6em;
  h1 {
    font-size: 24px;
    color: #333333;
  }
  h2 {
    font-size: 18px;
    color: #585752;
    margin: 1.5em 0 3em;
    font-weight: normal;
  }
  p {
    line-height: 2;
    color: #524E4C;
    font-size: 14px;
  }
`

function App({ children, order, padding }) {
  return (
    <Wrap className="g-width g-flex">
      <Info style={{order, padding}}>
        {children[0]}
      </Info>
      <div>
        {children[1]}
      </div>
    </Wrap>
  );
}

export default App;
