import React from 'react';
import styled from 'styled-components'
import code1 from '../../../assets/about/code1.png'
import code2 from '../../../assets/about/code2.png'

const Title = styled.h1`
  font-size: 26px;
  text-align: center;
  padding: 3em 0 1em;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
  span {
    font-size: 16px;
    color: #666;
    padding-left: 1em;
  }
`
const CardBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10em;
`
const Card = styled.div`
  padding: 3em;
  background-color: #EDEDED;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 2.5;
  color: #666666;
  .first-card {
    margin-right: 6em;
    svg {
      margin-right: .5em;
    }
  }
`

const info = [{name:'曾先生（海外业务）', qq: '78546003', mobile: '18688789415', img: code1}, {name:'袁女士（国内业务）', qq: '236846693', mobile: '18968221155', img: code2}]
const listItem = info.map(v => {
  return (
    <Card key={v.name}>
      <div className="first-card">
        <p>
          <span>{v.name}</span>
        </p>
        <p>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-qq"></use>
          </svg>
          <span>{v.qq}</span>
        </p>
        <p>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-phone"></use>
          </svg>
          <span>{v.mobile}</span>
        </p>
      </div>
      <div>
        <img width="100%" src={v.img} alt=""/>
      </div>
    </Card>
  )
})
function App() {
  return (
    <div className="g-width">
      <Title>商务合作<span>Business Cooperation</span></Title>
      <CardBar>{listItem}</CardBar>
    </div>
  );
}

export default App;
