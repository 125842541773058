import React from 'react';
import Card from './Card'

function App() {
  return (
    <Card>
      <div>
        <h1>全面、有效的运营体系</h1>
        <h2>专注用户运营 多方面专业服务</h2>
        <p>积分：打造“完成任务—获得积分—消耗积分—兑换奖品”的闭环系统</p>
        <p>签到：完成周期性签到，获得奖励，养成用户日常习惯</p>
        <p>节日活动：结合节假日、新闻热点等定制化活动促进用户活跃</p>
      </div>
      <div>
        <img width="100%" src={require('../../../assets/market/1.png')} alt=""/>
      </div>
    </Card>
  );
}

export default App;
