import React from 'react';
import styled from 'styled-components'

const H3 = styled.h3`
  padding: 2em 0;
  font-size: 46px;
  text-align: center;
  background-image: url(${require('../../../assets/market/4.png')});
  color: #fff;
`

function App() {
  return (
    <H3>沉浸式互动体验、海量广告资源、多样化流量场景</H3>
  );
}

export default App;
