import React from 'react';
import styled from 'styled-components'

const Wrap = styled.div`
  background-image: url(${require('../../../assets/connect/bottom.png')});
  ul {
    display: flex;
    padding: 5em 0;
    li {
      flex: 1;
      color: #75bfdb;
      font-size: 18px;
      padding: 3em 0;
      border-right: 2px solid #75bfdb;
      text-align: center;
      :last-child {
        border: none;
      }
    }
  }
`

function App() {
  return (
    <Wrap>
      <ul>
        <li>500+国家全球运营商支持</li>
        <li>通道稳定</li>
        <li>提供7*24小时</li>
        <li>安全防护</li>
        <li>高效平台</li>
      </ul>
    </Wrap>
  );
}

export default App;
