import React from 'react';
import styled from 'styled-components'

const Wrap = styled.div`
  text-align: center;
  padding: 5em 0;
  h1 {
    font-size: 24px;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      bottom: -.8em;
      left: 50%;
      width: 3.5em;
      transform: translateX(-50%);
      border-bottom: 1px solid #495060;
    }
    ::after {
      content: '';
      width: .46em;
      height: .46em;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #495060;
      position: absolute;
      bottom: -1.04em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  p {
    font-size: 14px;
    color: rgb(45, 153, 192);
    padding-top: 3em;
  }
`

function App({children}) {
  return (
    <Wrap>
      {children}
    </Wrap>
  );
}

export default App;
