import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './views/Home'
import About from './views/About'
import Market from './views/Market'
import Connect from './views/Connect'

function App() {
  return (
    <Router className="App">
      <ScrollToTop>
        <Header />
        <Route exact path="/" render={() => <Redirect to="/home"/>} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/market" component={Market} />
        <Route path="/connect" component={Connect} />
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
