import React from 'react';
import styled from 'styled-components'

const BannerText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  color: #fff;
  font-size: 40px;
  line-height: 2;
`

function App() {
  return (
    <div className="g-min-width">
      <img width="100%" src={require('../../../assets/market/banner.png')} alt="关于我们"/>
      <BannerText className="g-width">
        <p>运营、营销、变现</p>
        <p>一站式服务，简单有效</p>
      </BannerText>
    </div>
  );
}

export default App;
