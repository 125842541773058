import React, { useEffect } from 'react';
import styled from 'styled-components'

const Map = styled.div`
  width: 100%;
  height: 400px;
`

function App() {
  useEffect(() => {
    const { BMap } = window
    // 百度地图API功能
    const map = new BMap.Map('allmap')
    const point = new BMap.Point(116.331398, 39.897445)
    map.centerAndZoom(point, 12)
    map.enableScrollWheelZoom(true)
    // 创建地址解析器实例
    const myGeo = new BMap.Geocoder()
    // 将地址解析结果显示在地图上,并调整地图视野
    myGeo.getPoint('杭州市华业大厦', function (point) {
      if (point) {
        map.centerAndZoom(point, 18)
        map.addOverlay(new BMap.Marker(point))
      } else {
        alert('您选择地址没有解析到结果!')
      }
    }, '杭州市')
  });
  return (
    <div className="g-width">
      <Map id="allmap"></Map>
    </div>
  );
}

export default App;
