import React from 'react';
import styled from 'styled-components';

const IconList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5em 0;
`
const TextListWrap = styled.div`
  background-image: linear-gradient(250deg,rgba(255,97,58,1),rgba(243,81,41,1));
`
const TextList = styled.ul `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0;
  color: #fff;
`

function App() {
  return (
    <div>
      <div className="g-width">
        <IconList>
          <li><img width="100%" src={require('../../../assets/icon/icon1.png')} alt="icon1"/></li>
          <li><img width="100%" src={require('../../../assets/icon/icon2.png')} alt="icon2"/></li>
          <li><img width="100%" src={require('../../../assets/icon/icon3.png')} alt="icon3"/></li>
          <li><img width="100%" src={require('../../../assets/icon/icon4.png')} alt="icon4"/></li>
          <li><img width="100%" src={require('../../../assets/icon/icon5.png')} alt="icon5"/></li>
          <li><img width="100%" src={require('../../../assets/icon/icon6.png')} alt="icon6"/></li>
        </IconList>
      </div>
      <TextListWrap className="g-min-width">
        <TextList className="g-width">
          <li>全网通营销平台</li>
          <li>全球语音、短信、流量、话费</li>
          <li>全球话费流量充值服务</li>
          <li>活动化营销</li>
          <li>媒体流量变现</li>
        </TextList>
      </TextListWrap>
    </div>
  );
}

export default App;
