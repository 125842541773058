import React from 'react';
import Banner from './components/Banner'
import Product from './components/Product'
import Platform from './components/Platform'
import BottomText from './components/BottomText'

function App() {
  return (
    <main>
      <Banner />
      <Product />
      <Platform />
      <BottomText />
    </main>
  );
}

export default App;
