import React, { useState } from 'react';
import Title from './Title';
import styled from 'styled-components'

const TabList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  li {
    flex: 1;
    padding: 2em 0;
    background-color: #fff;
    color: #2d99c0;
    transition: all .3s;
    cursor: pointer;
    &.active-tab {
      background-color: #2daeed;
      color: #fff;
    }
    :hover {
      background-color: #2daeed;
      color: #fff;
    }
    svg {
      font-size: 50px;
    }
    p {
      margin-top: 1em;
      font-size: 14px;
    }
  }
`
const TabContent = styled.ul`
  background-color: #2daeed;
  padding: 4em 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  li {
    flex: 1;
    font-size: 16px;
    color: #fff;
    line-height: 2;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
  }
`

const listInfo = [{ icon: 'message', text: '全球短信' }, { icon: 'service', text: '全球语音通话' }, { icon: 'code', text: '语音验证码' }, { icon: 'call', text: '一键群呼' }, { icon: 'scan', text: '空号扫描' }, { icon: 'gird', text: '号码资源' }, { icon: 'card', text: '全球物联卡' }]
const contentInfo = [
  [{ title: '验证短信', info: '全球覆盖 5秒必达' }, { title: '营销短信', info: '智能分流 灵活营销' }, { title: '短信平台', info: '接入快 通道直达' }],
  [{ title: '全球语音通话', info: '全球直达，节约成本，清晰稳定，专业支持' }],
  [{ title: '语音验证码', info: '一点接入，简单集成；高效智能，安全稳定' }],
  [{ title: '一键群呼', info: '操作方便，高效省时' }],
  [{ title: '空号扫描', info: '节约成本，提高效率' }],
  [{ title: '全球DID/TOLL FREE号码资源', info: '提升企业形象，轻松提高客户服务质量' }],
  [{ title: '全球物联网卡', info: '全球覆盖，实现信息的全球追踪' }]
]
function App() {
  const [contentItems, setContentItems] = useState(contentInfo[0])
  const [activeIndex, setActiveIndex] = useState(0)
  const listItems = listInfo.map((v,i) => (
    <li onClick={switchContent.bind(this, i)} key={i} className={i === activeIndex ? 'active-tab' : ''}>
      <svg className="icon" aria-hidden="true">
        <use xlinkHref={`#icon-${v.icon}`}></use>
      </svg>
      <p>{v.text}</p>
    </li>
  ))
  function switchContent(index) {
    setContentItems(contentInfo[index])
    setActiveIndex(index)
  }
  return (
    <div>
      <div className="g-width">
        <Title>
          <h1>我们的产品</h1>
          <p>专注于出海的全球云通信服务平台</p>
        </Title>
        <TabList>{listItems}</TabList>
      </div>
      <TabContent>
        {
          contentItems.map(v => (
            <li key={v.title}>
              <p className="title">{v.title}</p>
              <p>{v.info}</p>
            </li>
          ))
        }
      </TabContent>
    </div>
  );
}

export default App;
