import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  background-color: #eee;
`
const LeftBar = styled.div`
`
const RightBar = styled.div`
  text-align: center;
  h2 {
    font-size: 24px;
    color: #A47C2A;
    position: relative;
    margin-bottom: 2em;
    ::before {
      content: '';
      position: absolute;
      top: -1.4em;;
      left: 50%;
      transform: translateX(-50%);
      width: 4em;
      height: 6px;
      background-image: linear-gradient(90deg, rgba(254,103,70,1) 0%, rgba(255,133,79,1) 100%);
    }
  }
  li {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      padding: 10px 30px;
      flex: 1;
      text-align: right;
    }
    p:first-child {
      order: 0;
    }
    p:last-child {
      font-size: 22px;
      color: #FF613A;
      order: 1;
      text-align: left;
      border-left: 1px solid #496074;
      position: relative;
      ::after {
        content: '';
        width: .5em;
        height: 2px;
        transform: translateY(-50%);
        background-color: #496074;
        position: absolute;
        left: -.25em;
        top: 50%;
      }
    }
  }
  li:nth-child(2n) {
    p:first-child {
      order: 1;
      text-align: left;
    }
    p:last-child {
      order: 0;
      text-align: right;
      border-left: none;
      border-right: 1px solid #496074;
      ::after {
        right: -.29em;
        left: auto;
      }
    }
  }
`

const listInfo = [{top: '游全球扩展支持200+国家充值', bottom: '2020-04'}, {top: '游全球业务新增菲律宾地区充值业务', bottom: '2019-01'}, {top: '响应“一带一路”政策，以印尼为起点，开展游全球业务', bottom: '2018-08'}, {top: '注册公众号【领流量】', bottom: '2015-01'}, {top: '全网通流量平台诞生', bottom: '2014-06'}, {top: '金橘科技正式成立', bottom: '2014-04'}]
const listItems = listInfo.map((value, index) => {
  return (
    <li key={index}>
      <p>{value.top}</p>
      <p>{value.bottom}</p>
    </li>
  )
})
function TimeLine() {
  return (
    <Wrap className="g-flex g-min-width">
      <LeftBar>
        <img width="100%" src={require('../../../assets/home/timeLine.png')} alt="公司大事记" />
      </LeftBar>
      <RightBar>
        <h2>公司大事记</h2>
        <ul>{listItems}</ul>
      </RightBar>
    </Wrap>
  );
}

export default TimeLine;
