import React from 'react';
import Card from './Card'

function App() {
  return (
    <Card>
      <div>
        <h1>媒体流量变现</h1>
        <h2>无门槛、高回报，一键自助投放</h2>
        <p>Api对接：话费、流量、视频会员</p>
        <p>分销体系：一键自助投放，无需预付费，坐等分成</p>
      </div>
      <div>
        <img width="100%" src={require('../../../assets/market/3.png')} alt=""/>
      </div>
    </Card>
  );
}

export default App;
