import React from 'react';
import Banner from './components/Banner'
import Details from './components/Details'
import Map from './components/Map'
import Cooperation from './components/Cooperation'

function App() {
  return (
    <main>
      <Banner />
      <Details />
      <Map />
      <Cooperation />
    </main>
  );
}

export default App;
