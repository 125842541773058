import React from 'react';
import Swiper from './components/Swiper'
import AboutUs from './components/AboutUs'
import TimeLine from './components/TimeLine'
import Location from './components/Location'
import FooterIcon from './components/FooterIcon'

function Home() {
  return (
    <main className="Home">
      <Swiper />
      <AboutUs />
      <TimeLine />
      <Location />
      <FooterIcon />
    </main>
  );
}

export default Home;
