import React from 'react';
import Card from './Card'

function App() {
  return (
    <Card order="1" padding="0 0 0 6em">
      <div>
        <h1>活动化营销手段</h1>
        <h2>为广告主提供定制化解决方案</h2>
        <p>活动拉新：引导分裂式社交传播来拉新，获客成本低</p>
        <p>价值转化：通过活动载体，以多种利益点驱动，使存量客户用户转化为价值用户</p>
      </div>
      <div>
        <img width="100%" src={require('../../../assets/market/2.png')} alt=""/>
      </div>
    </Card>
  );
}

export default App;
