import React from 'react';
import Title from './Title';
import styled from 'styled-components'

const MainBar = styled.div`
  display: flex;
  text-align: right;
  ul {
    flex: 1;
    text-align: left;
    &.right-bar {
      text-align: right;
      .number::after {
        left: auto;
        right: 3em;
      }
    }
  }
  >div {
    flex: 5;
  }
  li {
    padding: 3em 0;
  }
  .number {
    width: 2em;
    height: 2em;
    line-height: 2em;
    background-color: rgb(45,153,192);
    box-shadow: 0 0 0 .2em rgb(175,216,231);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 18px;
    display: inline-block;
    position: relative;
    ::after {
      content: '';
      width: 8em;
      border-bottom: 1px dashed #000;
      top: 50%;
      left: 3em;
      transform: translateY(-50%);
      position: absolute;
    }
  }
  .text {
    color: #2d98c1;
    line-height: 3;
  }
`

function App() {
  return (
    <div className="g-width">
      <Title>
        <h1>平台概览</h1>
        <p>耗资2000万研发的集成通信平台，让客户一号多用</p>
      </Title>
      <MainBar>
        <ul>
          <li>
            <p className="number">1</p>
            <p className="text">账户验证</p>
          </li>
          <li>
            <p className="number">2</p>
            <p className="text">在线充值</p>
          </li>
          <li>
            <p className="number">3</p>
            <p className="text">短信语音功能集成</p>
          </li>
        </ul>
        <div>
          <img width="100%" src={require('../../../assets/connect/platform.png')} alt=""/>
        </div>
        <ul className="right-bar">
          <li>
            <p className="number">4</p>
            <p className="text">实时运营商状态</p>
          </li>
          <li>
            <p className="number">5</p>
            <p className="text">定时发送，批量发送</p>
          </li>
          <li>
            <p className="number">6</p>
            <p className="text">可视化数据报表</p>
          </li>
        </ul>
      </MainBar>
    </div>
  );
}

export default App;
