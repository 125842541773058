import React from 'react';

function App() {
  return (
    <div className="g-min-width">
      <img width="100%" src={require('../../../assets/about/banner.png')} alt="关于我们"/>
    </div>
  );
}

export default App;
