import React from 'react';
import styled from 'styled-components';

const About = styled.div`
  padding: 8em 0;
`
const LeftBar = styled.div`
  h2 {
    font-size: 24px;
    position: relative;
    margin-bottom: 2em;
    ::before {
      content: '';
      position: absolute;
      top: -1.4em;;
      left: 0;
      width: 4em;
      height: 6px;
      background-image: linear-gradient(90deg, rgba(254,103,70,1) 0%, rgba(255,133,79,1) 100%);
    }
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 1.5em;
  }
`
const RightBar = styled.div`
  padding-left: 5em;
`
const AboutList = styled.ul`
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 10em;
  p {
    font-size: 24px;
    color: #FE6B43;
    margin-bottom: 1em;
  }
  span {
    color: #808892;
  }
`
const AboutListText = [{top: '7YEARS',bottom: '7年的经验'}, {top: '10000+',bottom: '一百万+用户共同选择'}, {top: '100',bottom: '一百多个行业'}, {top: '50,000',bottom: '成功案例'}]
const AboutListItem = AboutListText.map((value, index) => {
  return (
    <li key={index}>
      <p>{value.top}</p>
      <span>{value.bottom}</span>
    </li>
  )
})
function AboutUs() {
  return (
    <About className="g-width g-min-width">
      <div className="g-flex">
        <LeftBar>
          <h2>关于我们</h2>
          <p>杭州金橘科技有限公司成立于2014年，是一家从事移动互联网服务的高新技术企业。立足技术开发，发挥资源渠道优势，结合营销策划能力，围绕“通讯服务”领域，为企业提供优质、便捷、高效的移动互联网综合服务。</p>
          <p>于2015年打造了全网通流量平台，为行业客户提供移动营销一站式综合解决方案。并于2018年响应“一带一路”国家战略，开启游全球业务，为中国的出海企业和海外从业人员提供海外通讯支撑服务。</p>
          <p>目前金橘科技已与多家知名企业达成长期稳定合作关系，为其提供移动商务应用服务，帮助其及时、准确地传递资讯和服务信息。领域涉及跨国银行、国际公司、跨境电商、旅游公司、出海游戏公司、出海制造业等。</p>
          <p>还积极关注金融科技的发展，以新媒体、新维度、新创构运营战略为依托，为互联网金融、互联网+保险等领域代运营提供有力支持。</p>
        </LeftBar>
        <RightBar>
          <img width="100%" src={require('../../../assets/home/about.png')} alt="联系我们"/>
        </RightBar>
      </div>
      <AboutList>{AboutListItem}</AboutList>
    </About>
  );
}

export default AboutUs;
